import React, { Suspense } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { AuthProvider } from "../context/AuthContex";
import { LayoutProvider } from "../context/LayoutContex";
import HashRoute from "./hash.route";
import MainContainer from "../components/layout/MainContainer";
import Header from "../components/layout/Header";
import LeftNav from "../components/layout/LefNav";
import ChatBot from "../components/chatbot/ChatBot";
import MainContent from "../components/layout/MainContent";


export default function RoutesConfig() {
  return (
    <Suspense
      fallback={
        <div className="loader" > <RefreshIcon  /></div>
      }
     
    >
      <AuthProvider>
        <LayoutProvider>
          <MainContainer>
            <Header />
            <LeftNav />
            <MainContent>
              <HashRoute />
              <ChatBot />
            </MainContent>
          </MainContainer>
        </LayoutProvider>
      </AuthProvider>
    </Suspense>
  );
}
