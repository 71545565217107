import React, { useContext, useState } from "react";
import LayoutContext from "../../../context/LayoutContex";
import {
  Business,
  Cake,
  Close,
  Edit,
  Email,
  FamilyRestroom,
  NearMe,
  Person,
  PhoneAndroid,
  Send,
  SurfingSharp,
} from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";
import AuthContext from "../../../context/AuthContex";
import InputForm from "../../../components/input/InputForm";

export default function UserInfo() {
  const { color, isLeftNavOpen } = useContext(LayoutContext);
  const open = isLeftNavOpen ? "-full" : "";
  const { user } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({});

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCloseEditClick = () => {
    setIsEditing(false);
  };

  const handleSendClick = () => {
    setIsEditing(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className={`GM__${color}-profile${open}-info`}>
      <div className={`GM__${color}-profile${open}-info-image`}>
        <img src={user?.photoURL} alt="User" />
      </div>
      {isEditing ? (
        <div className={`GM__${color}-profile${open}-info-username`}>
          <InputForm
            type="text"
            name="username"
            value={editedUser.username || ""}
            onChange={onChange}
            variant="standard"
            disabled={false}
            label="Usuario"
          />
        </div>
      ) : (
        <h2 className={`GM__${color}-profile${open}-info-username`}>
          {user?.username}
        </h2>
      )}

      <span className={`GM__${color}-profile${open}-info-rol`}>
        {user?.rol}
      </span>
      <div className={`GM__${color}-profile${open}-info-details`}>
        {isEditing ? (
          <>
            <InputForm
              type="email"
              name="email"
              value={editedUser.email || ""}
              onChange={onChange}
              variant="standard"
              disabled={true}
              label="Correo"
            />
            <InputForm
              type="text"
              name="name"
              value={editedUser.name || ""}
              onChange={onChange}
              variant="standard"
              label="Nombre"
            />
            <InputForm
              type="text"
              name="surname"
              value={editedUser.surname || ""}
              onChange={onChange}
              variant="standard"
              label="Apellidos"
            />
            <InputForm
              type="text"
              name="surname"
              value={editedUser.phone || ""}
              onChange={onChange}
              variant="standard"
              label="Telefono"
            />
            <InputForm
              type="date"
              name="surname"
              value={editedUser.birthday || "0/0/0"}
              onChange={onChange}
              variant="standard"
              label="Cumpleaños"
            />
            <InputForm
              type="text"
              name="surname"
              value={editedUser.institution || ""}
              onChange={onChange}
              variant="standard"
              label="Institucion"
            />
          </>
        ) : (
          <ul>
            <li>
              <IconButton>
                <Email />
              </IconButton>
              <p>{user?.email}</p>
              <Divider />
            </li>
            <li>
              <IconButton>
                <Person />
              </IconButton>
              <p>{user?.name}</p>
              <Divider />
            </li>
            <li>
              <IconButton>
                <FamilyRestroom />
              </IconButton>
              <p>{user?.surname}</p>
              <Divider />
            </li>
            <li>
              <IconButton>
                <PhoneAndroid />
              </IconButton>
              <p>{user?.phone}</p>
              <Divider />
            </li>
            <li>
              <IconButton>
                <Cake />
              </IconButton>
              <p>{user?.birthday}</p>
              <Divider />
            </li>
            <li>
              <IconButton>
                <Business />
              </IconButton>
              <p>{user?.institution}</p>
              <Divider />
            </li>
          </ul>
        )}
      </div>
      <div className={`GM__${color}-profile${open}-info-icons`}>
        <IconButton onClick={handleEditClick}>
          <Edit />
        </IconButton>
        {isEditing && (
          <>
            <IconButton onClick={handleCloseEditClick}>
              <Close />
            </IconButton>
            <IconButton onClick={handleSendClick}>
              <Send />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
}
