import React, { useContext } from 'react';
import LayoutContext from '../../../context/LayoutContex';

export default function UserContent() {
    const { color, isLeftNavOpen } = useContext(LayoutContext);
    const open = isLeftNavOpen ? '-full' : '';
  return (
    
    <div className={`GM__${color}-profile${open}-content`}>
      {/* Contenido del usuario */}
    </div>
  );
}