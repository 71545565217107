import React, { createContext, useState } from 'react';

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const [color, setColor] = useState('default');
  const [isLeftNavOpen, setIsLeftNavOpen] = useState(true);
  const [language, setLanguage] = useState('es');

  const toggleColor = (newColor) => {
    setColor(newColor);
  };

  const toggleLeftNav = () => {
    setIsLeftNavOpen(!isLeftNavOpen);
  };

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <LayoutContext.Provider
      value={{
        color,
        isLeftNavOpen,
        language,
        toggleColor,
        toggleLeftNav,
        changeLanguage,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;