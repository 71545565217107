import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../views/Home'
import Dashboard from '../views/admin/Dashboard';
import Profile from '../views/admin/Profile';
import Students from '../views/admin/students/Students';
import Help from '../views/Help';
import StudentsDatails from '../views/admin/students/StudentsDatails';
import Chat from '../components/chat/Chat';
import ChatGrupal from '../components/chat/ChatGrupal';
export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/chat-group" element={<ChatGrupal />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/students" element={<Students />} />
      <Route path="/students/new" element={<Home />} />
      <Route path="/students/:id" element={<StudentsDatails />} />
      <Route path="/students/:id/news-observations" element={<Home />} />
      <Route path="/help" element={<Help />} />
      <Route path="/404" element={<Home />} />
    </Routes>
  )
}
