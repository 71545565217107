import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDecB8DuYFW_Nj2uyPRgzjRH1uXvGIF_q0",
  authDomain: "student-observation-app.firebaseapp.com",
  projectId: "student-observation-app",
  storageBucket: "student-observation-app.appspot.com",
  messagingSenderId: "458981203668",
  appId: "1:458981203668:web:e8b0b1f5be9e07f2cca541",
  measurementId: "G-0LNHEV3LH9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, analytics, auth };