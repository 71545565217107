// CallSetup.js
import React, { useEffect, useRef } from 'react';
import { Button, TextField, Grid, Typography, IconButton } from '@mui/material';
import { Mic, MicOff, Videocam, VideocamOff } from '@mui/icons-material';
import styled from 'styled-components';

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const Video = styled.video`
  width: 100%;
  max-width: 450px;
  height: auto;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;
`;

export default function CallSetup({
  peerId,
  remotePeerIdValue,
  setRemotePeerIdValue,
  handleJoinCall,
  localStream,
  isMicEnabled,
  isCameraEnabled,
  toggleMic,
  toggleCamera,
}) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && localStream) {
      setTimeout(() => {
        videoRef.current.srcObject = localStream;
      }, 500); // Ajusta el retraso según sea necesario
    }
  }, [localStream]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <VideoContainer>
          <Video ref={(ref) => ref && (ref.srcObject = localStream)} autoPlay playsInline muted />
        </VideoContainer>
        <ControlsContainer>
          <IconButton onClick={toggleMic}>
            {isMicEnabled ? <Mic /> : <MicOff />}
          </IconButton>
          <IconButton onClick={toggleCamera}>
            {isCameraEnabled ? <Videocam /> : <VideocamOff />}
          </IconButton>
        </ControlsContainer>
        <Typography variant="body1" align="center" gutterBottom>
          Tu ID: {peerId}
        </Typography>
        <TextField
          value={remotePeerIdValue}
          onChange={(e) => setRemotePeerIdValue(e.target.value)}
          placeholder="Ingrese el ID del usuario remoto"
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleJoinCall} disabled={!remotePeerIdValue}>
          Llamar
        </Button>
      </Grid>
    </Grid>
  );
}