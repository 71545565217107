import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SnackBartAlert = ({ severity, message, position, openSnack, onClose  }) => {
  const [open, setOpen] = useState(openSnack || false); // Ajuste aquí para inicializar el estado abierto

  useEffect(() => {
    setOpen(openSnack); // Actualiza el estado abierto cuando openSnack cambia
  }, [openSnack]); // Asegúrate de que useEffect se dispare cuando openSnack cambie

  const handleClose = () => {
    setOpen(false);
    onClose(oncancel); 
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={position}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBartAlert;
