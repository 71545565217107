import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import Peer from "peerjs";
import CallSetup from "./CallSetup";
import CallRoom from "./CallRoom";
import Home from "../../views/Home";

const ChatContainer = styled.div`
  height: 85vh;
  width: 90vw;
  display: flex;
`;

const CallGrid = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Content = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ChatWrapper = styled.div`
  background-color: #e0e0e0;
  padding: 10px;
  width: ${(props) => (props.chatEnabled ? "400px" : "0")};
  transition: width 0.3s ease-in-out;
  overflow: hidden;
`;

export default function Chat() {
  const [peerId, setPeerId] = useState("");
  const [remotePeerIdValue, setRemotePeerIdValue] = useState("");
  const [isInCall, setIsInCall] = useState(false);
  const [isMicEnabled, setIsMicEnabled] = useState(true);
  const [isCameraEnabled, setIsCameraEnabled] = useState(true);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(true);
  const peerInstance = useRef(null);
  const localStreamRef = useRef(null);

  useEffect(() => {
    const peer = new Peer();
    peerInstance.current = peer;

    peer.on("open", (id) => {
      setPeerId(id);
    });

    peer.on("call", (call) => {
      call.answer(localStreamRef.current);
      call.on("stream", (remoteStream) => {
        setRemoteStream(remoteStream);
        setIsInCall(true);
      });
    });

    return () => {
      // Detener las pistas de la cámara y el micrófono al desmontar el componente
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      peer.destroy();
    };
  }, []);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        localStreamRef.current = stream;
        setLocalStream(stream);
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
      });
  }, []);

  const handleJoinCall = () => {
    const call = peerInstance.current.call(
      remotePeerIdValue,
      localStreamRef.current
    );
    call.on("stream", (remoteStream) => {
      setRemoteStream(remoteStream);
      setIsInCall(true);
    });
  };

  const toggleMic = () => {
    if (isMicEnabled) {
      localStream.getAudioTracks().forEach((track) => {
        track.enabled = false;
      });
      setIsMicEnabled(false);
    } else {
      localStream.getAudioTracks().forEach((track) => {
        track.enabled = true;
      });
      setIsMicEnabled(true);
    }
  };

  const toggleCamera = () => {
    if (isCameraEnabled) {
      localStream.getVideoTracks().forEach((track) => {
        track.stop();
      });
      setIsCameraEnabled(false);
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          localStreamRef.current.getVideoTracks().forEach((track) => {
            track.stop();
          });
          localStreamRef.current = stream;
          setLocalStream(stream);
          setIsCameraEnabled(true);
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    }
  };
  

  return (
    <ChatContainer>
      <CallGrid>
        <Content>
          {!isInCall ? (
            <CallSetup
              peerId={peerId}
              remotePeerIdValue={remotePeerIdValue}
              setRemotePeerIdValue={setRemotePeerIdValue}
              handleJoinCall={handleJoinCall}
              localStream={localStream}
              isMicEnabled={isMicEnabled}
              isCameraEnabled={isCameraEnabled}
              toggleMic={toggleMic}
              toggleCamera={toggleCamera}
            />
          ) : (
            <CallRoom
              localStream={localStream}
              remoteStream={remoteStream}
              isMicEnabled={isMicEnabled}
              isCameraEnabled={isCameraEnabled}
              toggleMic={toggleMic}
              toggleCamera={toggleCamera}
              setIsChatVisible={setIsChatVisible}
            />
          )}
        </Content>
        <ChatWrapper chatEnabled={isChatVisible}>
          <Home />
        </ChatWrapper>
      </CallGrid>
    </ChatContainer>
  );
}
