// ModalOptions.jsx
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const ModalOptions = ({ onClose, onFullscreen }) => {
  return (
    <div className="modal-options">
      <Tooltip title="Cerrar">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Pantalla completa">
        <IconButton onClick={onFullscreen}>
          <FullscreenIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ModalOptions;