import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../service/config/firebase';
import { addUserWithGoogle, getUserWhithui, getUserWhithuid } from '../service';
import { Alert, Snackbar } from '@mui/material';
import SnackBartAlert from '../components/SnackBartAlert';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);
  const getNewToken = async (retryCount = 0) => {
    const firebaseUser = getAuth().currentUser;
    if (firebaseUser) {
      try {
        const newToken = await firebaseUser.getIdToken(true);
        return newToken;
      } catch (error) {
        if (retryCount < 1) {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          return getNewToken(retryCount + 1);
        } else {
          throw new Error('Failed to get new token after retry');
        }
      }
    } else {
      throw new Error('User is not authenticated');
    }
  };
  const getUserById = async (uid) => {
    try {
      const newToken = await getNewToken();
      const response = await getUserWhithuid(uid, newToken);
      const userData = { ...response.data, token: newToken };
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    } catch (error) {
      console.error('Error getting user by ID:', error);
    }
  };

  const loginWithEmailAndPassword = async (email, password) => {
    try {
      const response = await axios.post('/api/login', { email, password });
      const userData = response.data;
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const { uid, email, displayName, photoURL } = result.user;
      const token = await auth.currentUser.getIdToken();
      const userData = {
        uid,
        email,
        displayName,
        photoURL,
        token, 
      };

      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      try {
        const resultAdd = await addUserWithGoogle(userData)
        if (resultAdd.data) {
          setSnackbarSeverity('success');
          setSnackbarMessage(resultAdd.data.message);
          setSnackbarOpen(true);
        }
        else {
          setSnackbarSeverity('error');
          setSnackbarMessage('Ha ocurrido un error con la peticion a la api, estamos trabajando en la solución.');
          setSnackbarOpen(true);
        }
        
      } catch (error) {
        <SnackBartAlert severity="error" message={"Se encontro un error: "+ error }position={{ vertical: 'top', horizontal: 'right' }} />

      }

    } catch (error) {
      if (error.code === 'auth/cancelled-popup-request') {
        setSnackbarSeverity('warning');
        setSnackbarMessage(`Se cancela la operacion de incio de sesion :(`);
        setSnackbarOpen(true);
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(`Se cancela la operacion de incio de sesion :( `);
        setSnackbarOpen(true);
      }
      
    }
  };

  const logout = () => {
    setUser(null);
    setSnackbarSeverity('success');
    setSnackbarMessage(`¡Se cerro la sesión correctamente!`);
    setSnackbarOpen(true);    
    localStorage.removeItem('user');

  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  useEffect(() => {
    if (user && user.uid) {
      getUserById(user.uid);
    }
  }, [user]);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // El usuario está autenticado
        getUserById(user.uid);
      } else {
        // El usuario no está autenticado
        setUser(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  return (
    <>
      <AuthContext.Provider value={{ user, loginWithEmailAndPassword, loginWithGoogle, logout, getNewToken }}>
        {children}
      </AuthContext.Provider>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AuthContext;