import React, { useContext, useState } from 'react';
import Modal from './Modal';
import AuthContext from '../../context/AuthContex';
import Input from '../input/InputForm';
import ButtonContinued from '../button/ButtonContinued';

const LoginModal = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { loginWithEmailAndPassword, loginWithGoogle } = useContext(AuthContext);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    loginWithEmailAndPassword(email, password);
    onClose();
  };

  const handleGoogleLogin = () => {
    loginWithGoogle();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <h2>Iniciar sesión</h2>
      <Input
        label="Email"
        type="email"
        value={email}
        onChange={handleEmailChange}
      />
      <Input
        label="Contraseña"
        type="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <ButtonContinued onClick={handleLogin}>Iniciar sesión</ButtonContinued>
      <ButtonContinued onClick={handleGoogleLogin}>Iniciar sesión con Google</ButtonContinued>
    </Modal>
  );
};

export default LoginModal;