// Home.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import "../index.css";
import CallButton from "../components/button/CallButton";
import notificationSound from "../assets/notification_sound.mp3";
import { Modal } from "@mui/material";
import { FileCopy, Image } from "@mui/icons-material";
import ModalImg from "../components/modal/ModalImg";
const Home = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const navigate = useNavigate();
  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  useEffect(() => {
    const usersSocket = io(`${process.env.REACT_APP_API_SERVER_CHATBOT}/users`);
    setSocket({ usersSocket });

    usersSocket.on("connect", () => {
      console.log("Conectado al servidor de sockets de usuarios");
      setUserId(usersSocket.id);
      usersSocket.emit("userConnected", usersSocket.id);
    });

    usersSocket.on("message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      if (message.senderId !== userId) {
        playNotificationSound();
      }
    });

    usersSocket.on("userConnected", (user) => {
      setUsers((prevUsers) => [...prevUsers, user]);
    });

    usersSocket.on("userDisconnected", (userId) => {
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    });

    return () => {
      usersSocket.disconnect();
    };
  }, []);

  const sendMessage = () => {
    if (newMessage.trim() !== "") {
      const message = {
        text: newMessage,
        type: "text",
        senderId: userId,
      };
      socket.usersSocket.emit("message", message);
      setNewMessage("");
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileData = {
        name: file.name,
        type: file.type,
        data: reader.result,
        senderId: userId,
      };
      socket.usersSocket.emit("uploadFile", fileData);
    };

    reader.readAsDataURL(file);
  };

  const getFileExtension = (filename) => {
    console.log(filename);
    if (!filename || typeof filename !== "string") {
      return "";
    }
    const lastDotIndex = filename.lastIndexOf(".");
    return lastDotIndex !== -1 ? filename.slice(lastDotIndex + 1) : "";
  };

  const handleCallStart = () => {
    navigate("/chat");
  };
  const handleImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImageUrl(null);
  };
  const handlePreviewClick = (message) => {
    const fileUrl = `${process.env.REACT_APP_API_SERVER_CHATBOT}${message.file.url}`;
    setModalContent(fileUrl);
    setShowModal(true);
  };
  return (
    <div className="chat-card">
      <div className="chat-header">
        <h2>Chat</h2>
        <div className="user-list">
          {users.map((user) => (
            <span key={user.id} className="user-item">
              {user.name}
            </span>
          ))}
        </div>
        {users.length > 0 && (
          <CallButton
            receiverId={users.filter((user) => user.id !== userId)[0]?.id}
            onCallStart={handleCallStart}
          />
        )}
      </div>
      <div className="messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${
              message.senderId === userId ? "sent" : "received"
            }`}
          >
            <div className="message-content">
              {message.type === "text" ? (
                <p>{message.text}</p>
              ) : (
                <>
                  <div className="file-preview">
                    {message.file.type.startsWith("image/") ||
                    message.file.type === "video/mp4" ||
                    message.file.type === "image/gif" ? (
                      <div
                        onClick={() => handlePreviewClick(message)}
                        style={{ maxWidth: "200px", cursor: "pointer" }}
                      >
                        {message.file.type.startsWith("image/") && (
                          <img
                            src={`${process.env.REACT_APP_API_SERVER_CHATBOT}${message.file.url}`}
                            alt={message.file.name}
                          />
                        )}
                        {message.file.type === "video/mp4" && (
                          <video
                            src={`${process.env.REACT_APP_API_SERVER_CHATBOT}${message.file.url}`}
                            alt={message.file.name}
                            controls
                          />
                        )}
                      </div>
                    ) : (
                      <span className="file-extension">
                        {getFileExtension(message.file.name)}
                      </span>
                    )}
                  </div>
                  <a
                    href={`${process.env.REACT_APP_API_SERVER_CHATBOT}${message.file.url}`}
                    download={message.file.name}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Descargar
                  </a>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Escribe un mensaje..."
        />
        <input type="file" onChange={handleFileUpload} />
        <button onClick={sendMessage}>Enviar</button>
      </div>
      {showModal && (
  <ModalImg onClose={handleCloseModal}>
    {modalContent && (
      <>
        {modalContent.includes('.jpg') ||
        modalContent.includes('.png') ||
        modalContent.includes('.gif') ? (
          <img src={modalContent} alt="Modal Content" style={{ maxHeight: 'auto', width: '50%'}}/>
        ) : modalContent.includes('.mp4') ? (
          <video src={modalContent} controls />
        ) : (
          <p>Formato de archivo no compatible</p>
        )}
      </>
    )}
  </ModalImg>
)}
    </div>
  );
};

export default Home;
