// VideoCall.js
import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import { Grid, Button, IconButton } from "@mui/material";
import { Mic, MicOff, Videocam, VideocamOff, CallEnd } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Home from "../../views/Home";
import "./VideoCall.css";

const VideoCall = () => {
  const [peers, setPeers] = useState([]);
  const [isMicEnabled, setIsMicEnabled] = useState(true);
  const [isCameraEnabled, setIsCameraEnabled] = useState(true);
  const [isChatVisible, setIsChatVisible] = useState(true);
  const socketRef = useRef();
  const userVideo = useRef();
  const peersRef = useRef([]);
  const roomId = "your-room-id";
  const navigate = useNavigate();

  useEffect(() => {
    socketRef.current = io.connect(`${process.env.REACT_APP_API_SERVER_CHATBOT}/users/call`);
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        userVideo.current.srcObject = stream;
        socketRef.current.emit("join-room", roomId);

        socketRef.current.on("all-users", (users) => {
          const uniqueUsers = Array.from(new Set(users));
          const peers = [];
          uniqueUsers.forEach((userId) => {
            if (userId !== socketRef.current.id) {
              const peer = createPeer(userId, socketRef.current.id, stream);
              peersRef.current.push({ peerID: userId, peer });
              peers.push(peer);
            }
          });
          setPeers(peers);
        });

        socketRef.current.on("user-joined", (payload) => {
          const peer = addPeer(payload.signal, payload.callerId, stream);
          peersRef.current.push({ peerID: payload.callerId, peer });
          setPeers((users) => [...users, peer]);
        });

        socketRef.current.on("receiving-returned-signal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          item.peer.signal(payload.signal);
        });

        socketRef.current.on("user-disconnected", (userId) => {
          const peerObj = peersRef.current.find((p) => p.peerID === userId);
          if (peerObj && peerObj.peer) {
            peerObj.peer.destroy();
            const peers = peersRef.current.filter((p) => p.peerID !== userId);
            peersRef.current = peers;
            setPeers(peers);
          }
        });
      });

    const handleBeforeUnload = () => {
      socketRef.current.emit("disconnect-user", roomId);
    };
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        const stream = userVideo.current.srcObject;
        stream.getVideoTracks().forEach((track) => {
          track.stop();
        });
        setIsCameraEnabled(false);
      }
    };
  
    document.addEventListener("visibilitychange", handleVisibilityChange);

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      socketRef.current.emit("disconnect-user", roomId);
      socketRef.current.disconnect();
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [roomId]);

  function createPeer(userId, caller, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("sending-signal", {
        userToSignal: userId,
        caller,
        signal,
      });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerId, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("returning-signal", { signal, callerId });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  const toggleMic = () => {
    const stream = userVideo.current.srcObject;
    if (isMicEnabled) {
      stream.getAudioTracks().forEach((track) => {
        track.enabled = false;
      });
      setIsMicEnabled(false);
    } else {
      stream.getAudioTracks().forEach((track) => {
        track.enabled = true;
      });
      setIsMicEnabled(true);
    }
  };

  const toggleCamera = () => {
    const stream = userVideo.current.srcObject;
    if (isCameraEnabled) {
      stream.getVideoTracks().forEach((track) => {
        track.stop();
      });
      setIsCameraEnabled(false);
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          userVideo.current.srcObject = stream;
          setIsCameraEnabled(true);
        })
        .catch((error) => {
          console.error("Error al acceder a la cámara:", error);
        });
    }
  };

  const handleEndCall = () => {
    socketRef.current.emit("disconnect-user", roomId);
    navigate("/");
  };

  return (
    <div className="chat-container">
      <div className="call-grid">
        <div className="content">
          <div className="video-container">
            <div className="video-grid">
              {peers.map((peer, index) => (
                <div key={index} className="video-item">
                  <Video peer={peer} />
                </div>
              ))}
            </div>
          </div>
          <div className="small-video-wrapper">
            <video
              ref={userVideo}
              autoPlay
              playsInline
              muted
              className="video small-video"
            />
          </div>
          <div className="controls-container">
            <IconButton onClick={toggleMic}>
              {isMicEnabled ? <Mic /> : <MicOff />}
            </IconButton>
            <IconButton onClick={toggleCamera}>
              {isCameraEnabled ? <Videocam /> : <VideocamOff />}
            </IconButton>
            <IconButton onClick={handleEndCall}>
              <CallEnd />
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsChatVisible(!isChatVisible)}
            >
              {isChatVisible ? "Ocultar chat" : "Mostrar chat"}
            </Button>
          </div>
        </div>
        <div className={`chat-wrapper ${isChatVisible ? "chat-visible" : ""}`}>
          <Home />
        </div>
      </div>
    </div>
  );
};

const Video = ({ peer }) => {
  const ref = useRef();

  useEffect(() => {
    try {
      peer.on("stream", (stream) => {
        ref.current.srcObject = stream;
      });
    } catch (error) {
      console.error("Error al manejar el evento 'stream':", error);
    }
  }, [peer]);

  return <video ref={ref} autoPlay playsInline className="video main-video" />;
};

export default VideoCall;