import React, { useContext } from 'react';
import LayoutContext from '../../context/LayoutContex';
import '../../assets/scss/profile.scss';
import UserInfo from './components/UserInfo';
import UserContent from './components/UserContent';

export default function Profile() {
  const { color } = useContext(LayoutContext);

  return (
    <div className={`GM__${color}-profile`}>
      <UserInfo />
      <UserContent />
    </div>
  );
}