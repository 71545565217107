import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomeDos from '../views/HomeDos';


export default function PublicRoutes() {
  console.log('PublicRoutes');
  return (
    <Routes>
      <Route path="/" element={<HomeDos/>} />
    </Routes>
  )
}
