import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Pagination,
  TextField,
  Button,
  Modal,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import "../../../assets/scss/students.scss";
import LayoutContext from "../../../context/LayoutContex";
import InputForm from "../../../components/input/InputForm";
import ButtonContinued from "../../../components/button/ButtonContinued";
import ImageStudent from "../../../assets/img/student.jpg";
import StudentsContext from "../../../context/StudentsContext";
import MassStudentModal from "../../../components/modal/MassStudentModal";

export default function Students() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedAge, setSelectedAge] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMassModalOpen, setIsMassModalOpen] = useState(false);
  const [studentData, setStudentData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { color } = useContext(LayoutContext);
  const { students, fetchStudents, createStudent, deleteStudentData } =
    useContext(StudentsContext);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setCurrentPage(1);
  };

  const handleAgeChange = (event) => {
    setSelectedAge(event.target.value);
    setCurrentPage(1);
  };

  const handleAddStudent = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleAddMassStudent = () => {
    setIsMassModalOpen(true);
  };
  const handleCloseMassModal = () => {
    setIsMassModalOpen(false);
  };

  const handleStudentDataChange = (event) => {
    setStudentData(event.target.value);
  };

  const handleSaveStudentData = () => {
    createStudent(studentData);
    setIsModalOpen(false);
    setStudentData("");
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  // Filtrar estudiantes según los criterios de búsqueda
  const filteredStudents = students
    ? students.filter((student) =>
        student.nombre
          ? student.nombre.toLowerCase().includes(searchTerm.toLowerCase())
          : false
      )
    : [];
  const itemsPerPage = 6;
  const totalPages = filteredStudents
    ? Math.ceil(filteredStudents.length / itemsPerPage)
    : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedStudents = filteredStudents
    ? filteredStudents.slice(startIndex, endIndex)
    : [];
  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  return (
    <div className={`GM__${color}-students`}>
      <div className={`GM__${color}-students-header`}>
        <InputForm
          label="Buscar"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          className={`GM__${color}-students-header-search`}
        />
        <InputForm
          label="Año"
          value={selectedYear}
          onChange={handleYearChange}
          variant="outlined"
          size="small"
          className={`GM__${color}-students-header-filter`}
        />
        <InputForm
          label="Edad"
          value={selectedAge}
          onChange={handleAgeChange}
          variant="outlined"
          size="small"
          className={`GM__${color}-students-header-filter`}
        />
        <ButtonContinued
          variant="contained"
          color="primary"
          onClick={handleAddStudent}
        >
          Agregar estudiante
        </ButtonContinued>
        <ButtonContinued
          variant="contained"
          color="primary"
          onClick={handleAddMassStudent}
        >
          Agregar en masa
        </ButtonContinued>
      </div>
      {paginatedStudents.length === 0 ? (
        <Typography
          variant="body1"
          align="center"
          className={`GM__${color}-students-empty`}
        >
          No se encontraron estudiantes.
        </Typography>
      ) : (
        <Grid container spacing={2} className={`GM__${color}-students-grid`}>
          {paginatedStudents.map((student) => (
            <Grid item key={student.id} xs={12} sm={6} md={4} lg={3}>
              <Card
                component={Link}
                to={`/students/${student.id}`}
                className={`GM__${color}-students-grid-card`}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={student.image || ImageStudent}
                  alt={student.nombre}
                  className={`GM__${color}-students-grid-card-image`}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {student.nombre}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Grado: {student.grado}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Edad: {calculateAge(student.fechaNacimiento)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          className={`GM__${color}-students-pagination`}
        />
      )}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className={`GM__${color}-students-modal`}>
          <h2>Agregar estudiante</h2>
          <InputForm
            label="Datos del estudiante"
            rows={4}
            value={studentData}
            onChange={handleStudentDataChange}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveStudentData}
          >
            Guardar
          </Button>
        </div>
      </Modal>
      <MassStudentModal
        isMassModalOpen={isMassModalOpen}
        setIsMassModalOpen={setIsMassModalOpen}
        handleCloseMassModal={handleCloseMassModal}
      />
    </div>
  );
}
