import React, { createContext, useState, useEffect, useContext } from 'react';
import {
  getStudents,
  addStudent,
  updateStudent,
  deleteStudent,
  addStudentsMassive,
  addFirma,
  deleteObservacionService,
  updateObservacionService,
  addObservacionService,
} from '../service';
import AuthContext from './AuthContex';
import SnackBartAlert from '../components/SnackBartAlert';

const StudentsContext = createContext();

export const StudentsProvider = ({ children }) => {
  const { getNewToken, user } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarPosition, setSnackbarPosition] = useState({ vertical: 'top', horizontal: 'center' });

  useEffect(() => {
    if (user) {
      fetchStudents();
    }
  }, [user]);

  const showSnackbar = (severity, message, position = { vertical: 'top', horizontal: 'center' }) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarPosition(position);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchStudents = async () => {
    try {
      const token = await getNewToken();
      const response = await getStudents(token);
      setStudents(response.data);
      showSnackbar('success', 'Estudiantes obtenidos correctamente');
    } catch (error) {
      console.error('Error fetching students:', error);
      showSnackbar('error', 'Error al obtener estudiantes...Por favor se paciente.');
    }
  };

  const createStudent = async (studentData) => {
    const token = await getNewToken();
    try {
      const response = await addStudent(token, studentData);
      setStudents((prevStudents) => [...prevStudents, response.data]);
      showSnackbar('success', 'Estudiante creado correctamente');
    } catch (error) {
      console.error('Error creating student:', error);
      showSnackbar('error', 'Error al crear estudiante');
    }
  };

  const updateStudentData = async (studentId, updatedData) => {
    const token = await getNewToken();
    try {
      const response = await updateStudent(studentId, updatedData, token);
      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === studentId ? { ...student, ...response.data } : student
        )
      );
      showSnackbar('success', 'Estudiante actualizado correctamente');
    } catch (error) {
      console.error('Error updating student:', error);
      showSnackbar('error', 'Error al actualizar estudiante');
    }
  };

  const deleteStudentData = async (studentId) => {
    const token = await getNewToken();
    try {
      await deleteStudent(token, studentId);
      setStudents((prevStudents) =>
        prevStudents.filter((student) => student.id !== studentId)
      );
      showSnackbar('success', 'Estudiante eliminado correctamente');
    } catch (error) {
      console.error('Error deleting student:', error);
      showSnackbar('error', 'Error al eliminar estudiante');
    }
  };

  const createStudentMassive = async (studentsData) => {
    const token = await getNewToken();
    try {
      const response = await addStudentsMassive(studentsData, token);
      if (response.data.success) {
        setStudents((prevStudents) => {
          if (Array.isArray(prevStudents)) {
            return [...prevStudents, ...response.data.students];
          } else {
            return response.data.students;
          }
        });
        showSnackbar('success', 'Estudiantes creados correctamente');
        return true;
      } else {
        showSnackbar('error', 'Error al crear estudiantes');
        return false;
      }
    } catch (error) {
      console.error('Error creating students:', error);
      showSnackbar('error', 'Error al crear estudiantes');
      return false;
    }
  };

  const uploadFirma = async (observacionId, tipoFirma, audioFile, studentId) => {
    try {
      const token = await getNewToken();
      const formData = new FormData();
      formData.append('observacionId', observacionId);
      formData.append('tipoFirma', tipoFirma);
      formData.append('audioFile', audioFile);

      const response = await addFirma(formData, studentId, token);
      showSnackbar('success', 'Firma subida exitosamente');
    } catch (error) {
      console.error('Error al subir la firma:', error);
      showSnackbar('error', 'Error al subir la firma');
    }
  };

  const deleteObservacion = async (observacionId) => {
    try {
      await deleteObservacionService(observacionId);
      setStudents((prevStudents) =>
        prevStudents.map((student) => ({
          ...student,
          observaciones: student.observaciones.filter(
            (observacion) => observacion.id !== observacionId
          ),
        }))
      );
      showSnackbar('success', 'Observación eliminada correctamente');
    } catch (error) {
      console.error('Error deleting observacion:', error);
      showSnackbar('error', 'Error al eliminar observación');
    }
  };

  const updateObservacion = async (updatedObservacion, idObservation) => {
    try {
      await updateObservacionService(updatedObservacion, idObservation);
      setStudents((prevStudents) =>
        prevStudents.map((student) => {
          if (student.id === updatedObservacion.studentId) {
            const updatedObservaciones = student.observaciones.map((observacion) => {
              if (observacion.id === updatedObservacion.id) {
                return updatedObservacion;
              }
              return observacion;
            });
            return { ...student, observaciones: updatedObservaciones };
          }
          return student;
        })
      );
      showSnackbar('success', 'Observación actualizada correctamente');
    } catch (error) {
      console.error('Error updating observacion:', error);
      showSnackbar('error', 'Error al actualizar observación');
    }
  };

  const addObservacion = async (observacionData, studentId) => {
    try {
      await addObservacionService(observacionData, studentId);
      showSnackbar('success', 'Observación agregada correctamente');
    } catch (error) {
      console.error('Error adding observacion:', error);
      showSnackbar('error', 'Error al agregar observación');
    }
  };

  return (
    <StudentsContext.Provider
      value={{
        students,
        createStudent,
        updateStudentData,
        deleteStudentData,
        fetchStudents,
        createStudentMassive,
        uploadFirma,
        deleteObservacion,
        updateObservacion,
        addObservacion,
      }}
    >
      {children}
      <SnackBartAlert
        severity={snackbarSeverity}
        message={snackbarMessage}
        position={snackbarPosition}
        openSnack={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </StudentsContext.Provider>
  );
};

export default StudentsContext;