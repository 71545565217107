import React from "react";
import { Toolbar, Typography, IconButton, Tooltip } from "@mui/material";
import { FilterList } from "@mui/icons-material";

const EnhancedTableToolbar = (props) => {
  const { numSelected, onAddObservationClick } = props;

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div">
          {numSelected} seleccionadas
        </Typography>
      ) : (
        <Typography variant="h6" id="tableTitle" component="div">
          Observaciones
        </Typography>
      )}
      <Tooltip title="Agregar observación">
        <IconButton onClick={onAddObservationClick}>
          <FilterList />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;