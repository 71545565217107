import React, { useContext } from 'react'
import LayoutContext from '../../context/LayoutContex';

export default function MainContainer({children}) {
    const { color, isLeftNavOpen } = useContext(LayoutContext);
    const open = isLeftNavOpen? "-full": "";
    return (
        <section className={`GM__${color}-container${open}`}>{children}</section>
        )
}
