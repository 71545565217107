import React, { useContext, useState } from "react";
import LayoutContext from "../../context/LayoutContex";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete, Edit, Mic } from "@mui/icons-material";
import FirmaModal from "../modal/FirmaModal";
import DeleteObservationModal from "../modal/DeleteObservationModal";
import UpdateObservationModal from "../modal/UpdateObservationModal";
import AddObservationModal from "../modal/AddObservationModal";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

export default function TableObservation({ student }) {
  const { color } = useContext(LayoutContext);
  const [selected, setSelected] = useState([]);
  const [firmaModalOpen, setFirmaModalOpen] = useState(false);
  const [editarModalOpen, setEditarModalOpen] = useState(false);
  const [eliminarModalOpen, setEliminarModalOpen] = useState(false);
  const [addObservationModalOpen, setAddObservationModalOpen] = useState(false);
  const [observacionSeleccionada, setObservacionSeleccionada] = useState(null);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFirmaClick = (observacion) => {
    setObservacionSeleccionada(observacion);
    setFirmaModalOpen(true);
  };

  const handleEditarClick = (observacion) => {
    setObservacionSeleccionada(observacion);
    setEditarModalOpen(true);
  };

  const handleEliminarClick = (observacion) => {
    setObservacionSeleccionada(observacion);
    setEliminarModalOpen(true);
  };

  const handleFirmaModalClose = () => {
    setFirmaModalOpen(false);
    setObservacionSeleccionada(null);
  };

  const handleEditarModalClose = () => {
    setEditarModalOpen(false);
    setObservacionSeleccionada(null);
  };

  const handleEliminarModalClose = () => {
    setEliminarModalOpen(false);
    setObservacionSeleccionada(null);
  };

  const handleAddObservationClick = () => {
    setAddObservationModalOpen(true);
  };

  const handleAddObservationModalClose = () => {
    setAddObservationModalOpen(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const playAudio = (url) => {
    const audio = new Audio(url);
    audio.play();
  };



  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onAddObservationClick={handleAddObservationClick}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Observación</TableCell>
                <TableCell>Seguimiento</TableCell>
                <TableCell>Firma del estudiante</TableCell>
                <TableCell>Firma del padre</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {student.observaciones.map((observacion) => {
                const isItemSelected = isSelected(observacion.id);
                const labelId = `enhanced-table-checkbox-${observacion.id}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, observacion.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={observacion.id}
                    selected={isItemSelected}
                  >
                    <TableCell>{observacion.fecha}</TableCell>
                    <TableCell>{observacion.observacion}</TableCell>
                    <TableCell>{observacion.seguimiento || "Sin seguimiento"}</TableCell>
                    <TableCell>
                      {observacion.firmaEstudiante ? (
                        <IconButton onClick={() =>
                          playAudio(observacion.firmaEstudiante)
                        }>
                          <Mic />
                        </IconButton>
                      ) : (
                        <span>Sin firma</span>
                      )}
                    </TableCell>
                    <TableCell>
                      {observacion.firmaPadre ? (
                        <IconButton onClick={() => playAudio(observacion.firmaPadre)}>
                          <Mic />
                        </IconButton>
                      ) : (
                        <span>Sin firma</span>
                      )}
                    </TableCell>
                    <TableCell>
                    <IconButton onClick={() => handleFirmaClick(observacion)}>
                      <Mic />
                    </IconButton>
                      <IconButton onClick={() => handleEditarClick(observacion)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleEliminarClick(observacion)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <FirmaModal
        open={firmaModalOpen}
        onClose={handleFirmaModalClose}
        observacion={observacionSeleccionada}
        studentId={student.id}
      />
      <DeleteObservationModal
        open={eliminarModalOpen}
        onClose={handleEliminarModalClose}
        observacion={observacionSeleccionada}
      />
      <UpdateObservationModal
        open={editarModalOpen}
        onClose={handleEditarModalClose}
        observacion={observacionSeleccionada}
      />
      <AddObservationModal
        open={addObservationModalOpen}
        onClose={handleAddObservationModalClose}
        studentId={student.id}
      />
    </div>
  );
}