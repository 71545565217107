// ModalImg.jsx
import React from "react";
import ModalOptions from "./ModalOptions";

const ModalImg = ({ onClose, children }) => {
  const handleFullscreen = () => {
    // Lógica para pasar a pantalla completa
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <ModalOptions onClose={onClose} onFullscreen={handleFullscreen} />
        <div
          className="modal-image-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalImg;
