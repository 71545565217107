// CallRoom.js
import React, { useRef, useEffect, useState } from 'react';
import { IconButton, Button } from '@mui/material';
import { Mic, MicOff, Videocam, VideocamOff } from '@mui/icons-material';
import styled from 'styled-components';

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MainVideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const MainVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SmallVideoWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 150px;
  height: 100px;
  z-index: 1;
  cursor: pointer;
`;

const SmallVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
`;

export default function CallRoom({
  localStream,
  remoteStream,
  isMicEnabled,
  isCameraEnabled,
  toggleMic,
  toggleCamera,
  setIsChatVisible,
}) {
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [isLocalVideoMain, setIsLocalVideoMain] = useState(false);

  useEffect(() => {
    if (localVideoRef.current && localStream) {
      localVideoRef.current.srcObject = localStream;
    }
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [localStream, remoteStream]);

  const handleSmallVideoClick = () => {
    setIsLocalVideoMain((prevState) => !prevState);
  };

  return (
    <VideoContainer>
      <MainVideoWrapper>
        <MainVideo
          ref={isLocalVideoMain ? localVideoRef : remoteVideoRef}
          autoPlay
          playsInline
        />
      </MainVideoWrapper>
      <SmallVideoWrapper onClick={handleSmallVideoClick}>
        <SmallVideo
          ref={isLocalVideoMain ? remoteVideoRef : localVideoRef}
          autoPlay
          playsInline
          muted
        />
      </SmallVideoWrapper>
      <ControlsContainer>
        <IconButton onClick={toggleMic}>
          {isMicEnabled ? <Mic /> : <MicOff />}
        </IconButton>
        <IconButton onClick={toggleCamera}>
          {isCameraEnabled ? <Videocam /> : <VideocamOff />}
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsChatVisible((prevVisible) => !prevVisible)}
        >
          Ver chat
        </Button>
      </ControlsContainer>
    </VideoContainer>
  );
}