import axios from "axios";
import SnackBartAlert from "../components/SnackBartAlert";

export async function addUserWithGoogle(userData) {
    try {
        const response = await axios({
            url: ` ${process.env.REACT_APP_API_SERVER}/user/add-user `,
            method: 'POST',
            data: {userData},
            headers: {
                'Content-Type': 'application/json'
            }

        });
        return response
        
    } catch (e) {
        return e;
    };
}
export async function getUserWhithuid(uid, token) {
    try {
        const response = await axios({
            url: ` ${process.env.REACT_APP_API_SERVER}/user/get-user/${uid}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
              }

        });
        return response
        
    } catch (e) {
        return e;
    };
}

export async function getStudents(token) {
  try {
    const response = await axios({
        url: `${process.env.REACT_APP_API_SERVER}/student/get-students`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
          }

    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function addStudent(studentData, token) {
  try {
    const response = await axios({
        url: `${process.env.REACT_APP_API_SERVER}/student/add-student`,
        method: 'POST',
        data: {studentData},
        headers: {
            Authorization: `Bearer ${token}`,
          }

    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateStudent(studentId, updatedData, token) {
  try {
    const response = await axios({
        url: `${process.env.REACT_APP_API_SERVER}/student/edit-student/${studentId}`,
        method: 'PUT',
        data: {updatedData},
        headers: {
            Authorization: `Bearer ${token}`,
          }

    });
    console.log(response)
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteStudent(studentId, token) {
  try {
        const response = await axios({
            url: `${process.env.REACT_APP_API_SERVER}/student/delete-student/${studentId}`,
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
              }
    
        });
        return response
  } catch (error) {
    throw error;
  }
}
export async function addStudentsMassive(studentsData, token) {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_SERVER}/student/add-students-massive`,
      method: 'POST',
      data: { studentsData },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}
export async function addFirma(formData, studentId, token) {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_SERVER}/student/upload-firma/${studentId}`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}
export async function updateObservacionService(updatedObservacion, idObservation) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_SERVER}/student/update-observacion/${idObservation}`,
      updatedObservacion
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteObservacionService(observacionId) {
  try {
    await axios.delete(
      `${process.env.REACT_APP_API_SERVER}/student/delete-observacion/${observacionId}`
    );
  } catch (error) {
    throw error;
  }
}
export async function addObservacionService(observacionData, studentId) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/student/add-observacion`,
      {
        observacionData,
        studentId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function generateExcelService(getNewToken, student) {
  try {
    const token = await getNewToken();  // Asegúrate de que esta función devuelve un string (el token).

    const response = await axios({
      url: `${process.env.REACT_APP_API_SERVER}/docs/generate-excel`,
      method: 'POST',
      data: { studentData: student },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;

    const fileName =`${student.nombre}-${formattedDate}.xlsx`;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.log('Error al generar el archivo Excel:', error);
  }
}
export async function generateWordService(getNewToken, student) {
  try {
    const token = await getNewToken();
    const response = await axios({
      url: `${process.env.REACT_APP_API_SERVER}/docs/generate-word`,
      method: 'POST',
      data: { studentData: student },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });

    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
    const fileName = `${student.nombre}-${formattedDate}.docx`;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.log('Error al generar el archivo de Word:', error);
  }
}
export async function generatePDFService(getNewToken, student) {
  try {
    // Generar el archivo Word y enviarlo al backend
    const token = await getNewToken();
    const wordResponse = await axios({
      url: `${process.env.REACT_APP_API_SERVER}/docs/generate-word`,
      method: 'POST',
      data: { studentData: student },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });

    // Enviar el archivo Word al backend para convertirlo a PDF
    // Enviar el archivo Word al backend para convertirlo a PDF
    const wordBase64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(wordResponse.data);
    });

    const pdfResponse = await axios({
      url: `${process.env.REACT_APP_API_SERVER}/docs/generate-pdf`,
      method: 'POST',
      data: { wordBase64 },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });


    // Descargar el archivo PDF
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
    const pdfFileName = `${student.nombre}-${formattedDate}.pdf`;
    const pdfUrl = window.URL.createObjectURL(new Blob([pdfResponse.data]));
    const pdfLink = document.createElement('a');
    pdfLink.href = pdfUrl;
    pdfLink.setAttribute('download', pdfFileName);
    document.body.appendChild(pdfLink);
    pdfLink.click();
    pdfLink.remove();
  } catch (error) {
    console.log('Error al generar el archivo PDF:', error);
  }
}
