import React, { useContext } from 'react'
import LayoutContext from '../../context/LayoutContex';

export default function MainContent({children}) {
    const { color, isLeftNavOpen } = useContext(LayoutContext);
    const open = isLeftNavOpen? "-full": "";
    
  return (
    <main className={`GM__${color}-content${open}`}>{children}</main>
  )
}
