import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/scss/layout.scss'; 
import './assets/scss/leftnav.scss'; 
import './assets/scss/header.scss'; 
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import RouteAll from './routes/routes';
import ConsoleArt from './consoleAlert';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter> 
      <RouteAll />
      <ConsoleArt />
  </HashRouter>
);

reportWebVitals();
