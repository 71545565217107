import React, { useContext, useState } from "react";
import LayoutContext from "../../context/LayoutContex";
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Box,
  Tooltip,
  Stack,
  Chip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ImageLogo from "../../assets/img/logo1.png";

import AuthContext from "../../context/AuthContex";
import LoginModal from "../modal/LoginModal";
import ButtonContinued from "../button/ButtonContinued";

const Header = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const { color } = useContext(LayoutContext);
  const { user, logout } = useContext(AuthContext);
  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);
  const [language, setLanguage] = useState("es");
  // Función para manejar la apertura del menú de usuario
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogin = () => {
    setOpenLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  // Contenido del menú de usuario
  const settings = [
    { label: "Dashboard", onClick: () => console.log("Dashboard clicked") },
    { label: "Perfil", onClick: () => console.log("Perfil clicked") },
    {
      label: "Cerrar sesión",
      onClick: () => {
        logout();
        handleCloseUserMenu();
      },
    },
  ];
  const handleLanguageMenuClick = () => {
  setOpenLanguageMenu(!openLanguageMenu);
};

const changeLanguage = (lang) => {
  setLanguage(lang);
};

  return (
    <>
      <div className={`GM__${color}-header-img`}><img style={{width: "40px", height: "40px"}} src={ImageLogo} alt="Logo" /></div>
      <header className={`GM__${color}-header`}>
        <div className={`GM__${color}-header-logo`}>AlumnaEyes</div>
        <div className={`GM__${color}-header-auth`}>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              {user ? (
                <>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip
                      onClick={handleOpenUserMenu}
                      label={user.username || user.email}
                      variant="outlined"
                      sx={{ p: 2, }}
                    />
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ p: 0, left: "-25px" }}
                    >
                      <Avatar
                        alt={user.username || user.email}
                        src="/static/images/avatar/2.jpg"
                      />
                    </IconButton>
                  </Stack>
                </>
              ) : (
                <>
                  <ButtonContinued onClick={handleLogin}>
                    Iniciar sesión
                  </ButtonContinued>
                </>
              )}
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <div key={setting.label}>
                  <MenuItem onClick={setting.onClick}>
                    <Typography textAlign="center">{setting.label}</Typography>
                    {setting.label === "Cambiar idioma" && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLanguageMenuClick();
                        }}
                      >
                        {openLanguageMenu ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    )}
                  </MenuItem>
                  {setting.label === "Cambiar idioma" && (
                    <Collapse
                      in={openLanguageMenu}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem onClick={() => changeLanguage("es")}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            checked={language === "es"}
                            disableRipple
                          />
                          <ListItemText primary="Español" />
                        </ListItem>
                        <ListItem onClick={() => changeLanguage("en")}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            checked={language === "en"}
                            disableRipple
                          />
                          <ListItemText primary="English" />
                        </ListItem>
                      </List>
                    </Collapse>
                  )}
                </div>
              ))}
            </Menu>
          </Box>
        </div>

        <LoginModal open={openLoginModal} onClose={handleCloseLoginModal} />
      </header>
    </>
  );
};

export default Header;
