import React, { useContext, useState } from "react";
import StudentsContext from "../../../context/StudentsContext";
import { useParams } from "react-router-dom";
import "../../../assets/scss/students.scss";
import LayoutContext from "../../../context/LayoutContex";
import ImageStudent from "../../../assets/img/student.jpg";
import { IconButton, Tooltip } from "@mui/material";
import {
  Article,
  Close,
  Delete,
  Description,
  Edit,
  Mic,
  PictureAsPdf,
  Send,
} from "@mui/icons-material";
import SnackBartAlert from "../../../components/SnackBartAlert";
import FirmaModal from "../../../components/modal/FirmaModal";
import TableObservation from "../../../components/table/TableObservation";
import { generateExcelService, generatePDFService, generateWordService } from "../../../service";
import AuthContext from "../../../context/AuthContex";

export default function StudentsDetails() {
  const { students, updateStudentData, fetchStudents } =
    useContext(StudentsContext);
  const { getNewToken } = useContext(AuthContext);
  const { color } = useContext(LayoutContext);
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const student = students.find((student) => student.id === id);
  const [editedStudent, setEditedStudent] = useState({
    lugarExpedicion: "Puero Asis",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  if (!student) {
    return <div>Estudiante no encontrado</div>;
  }
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCloseEditClick = () => {
    setIsEditing(false);
  };
  const handleSendClick = async () => {
    try {
      await updateStudentData(student.id, editedStudent);
      setIsSuccess(true);
      setIsEditing(false);
      fetchStudents();
    } catch (error) {
      setIsError(true);
      setErrorMessage(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedStudent((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
  };
  const handleGenerateExcel = async () => {
    await generateExcelService(getNewToken, student);
  };
  const handleGeneratePDF = async () => {
    await generatePDFService(getNewToken, student);
  };
  const handleGenerateWord = async () => {
    await generateWordService(getNewToken, student);
  };
  return (
    <div className={`GM__${color}-students-details`}>
      <SnackBartAlert
        severity={isError ? "error" : "success"}
        message={isError ? errorMessage : "¡Se ha modificado con exito!"}
        openSnack={isSuccess || isError}
      />
      <div className={`GM__${color}-profile-info-icons`}>
        {!isEditing && (
          <>
            <Tooltip title="Generar PDF">
              <IconButton onClick={handleGeneratePDF}>
                <PictureAsPdf />
              </IconButton>
            </Tooltip>
            <Tooltip title="Generar Excel">
              <IconButton onClick={handleGenerateExcel}>
                <Description />
              </IconButton>
            </Tooltip>
            <Tooltip title="Generar Word">
              <IconButton onClick={handleGenerateWord}>
                <Article />
              </IconButton>
            </Tooltip>
          </>
        )}
        <IconButton onClick={handleEditClick}>
          <Edit />
        </IconButton>
        {isEditing && (
          <>
            <IconButton onClick={handleCloseEditClick}>
              <Close />
            </IconButton>
            <IconButton onClick={handleSendClick}>
              <Send />
            </IconButton>
          </>
        )}
      </div>
      <h2>OBSERVADOR DEL ESTUDIANTE</h2>
      <div className={`GM__${color}-students-details-photo`}>
        <img
          src={student.photo || ImageStudent}
          alt={student.nombre}
          className={`GM__${color}-students-details-photo-img`}
        />
      </div>
      <div className={`GM__${color}-students-details-info`}>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Nombre del alumno
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="nombre"
                value={editedStudent?.nombre}
                onChange={handleInputChange}
                placeholder={student?.nombre}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.nombre
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Numero de documento
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="id"
                value={editedStudent?.id}
                onChange={handleInputChange}
                placeholder={student?.id}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.id
            )}
          </div>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            tipo de documento
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="tipoDocumento"
                value={editedStudent?.tipoDocumento}
                onChange={handleInputChange}
                placeholder={student?.tipoDocumento}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.tipoDocumento
            )}
          </div>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Lugar de expedición
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="lugarExpedicion"
                value={editedStudent?.lugarExpedicion}
                onChange={handleInputChange}
                placeholder={student?.lugarExpedicion}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.lugarExpedicion
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Fecha de nacimiento
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="fechaNacimiento"
                value={editedStudent?.fechaNacimiento}
                onChange={handleInputChange}
                placeholder={student?.fechaNacimiento}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.fechaNacimiento
            )}
          </div>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Edad del estudiante
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="number"
                name="edad"
                value={editedStudent?.edad}
                onChange={handleInputChange}
                placeholder={student?.edad}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.edad
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Padre del alumno
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="nombrePadre"
                value={editedStudent?.nombrePadre}
                onChange={handleInputChange}
                placeholder={student?.nombrePadre}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.nombrePadre
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Ocupacion
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="ocupacionPadre"
                value={editedStudent?.ocupacionPadre}
                onChange={handleInputChange}
                placeholder={student?.ocupacionPadre}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.ocupacionPadre
            )}
          </div>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Celular del padre
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="celularPadre"
                value={editedStudent?.celularPadre}
                onChange={handleInputChange}
                placeholder={student?.celularPadre}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.celularPadre
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Madre del alumno
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="nombreMadre"
                value={editedStudent?.nombreMadre}
                onChange={handleInputChange}
                placeholder={student?.nombreMadre}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.nombreMadre
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Ocupacion
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="ocupacionMadre"
                value={editedStudent?.ocupacionMadre}
                onChange={handleInputChange}
                placeholder={student?.ocupacionMadre}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.ocupacionMadre
            )}
          </div>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Celular de la madre
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="celularMadre"
                value={editedStudent?.celularMadre}
                onChange={handleInputChange}
                placeholder={student?.celularMadre}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.celularMadre
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Acudiente
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="acudiente"
                value={editedStudent?.acudiente}
                onChange={handleInputChange}
                placeholder={student?.acudiente}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.acudiente
            )}
          </div>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Celular del acudiente
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="celularAcudiente"
                value={editedStudent?.celularAcudiente}
                onChange={handleInputChange}
                placeholder={student?.celularAcudiente}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.celularAcudiente
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Ocupacion
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="ocupacionAcudiente"
                value={editedStudent?.ocupacionAcudiente}
                onChange={handleInputChange}
                placeholder={student?.ocupacionAcudiente}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.ocupacionAcudiente
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            N° de hermanos
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="number"
                name="numeroHermanos"
                value={editedStudent?.numeroHermanos}
                onChange={handleInputChange}
                placeholder={student?.numeroHermanos}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.numeroHermanos
            )}
          </div>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Puesto que ocupa
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="puestoQueSolicitaCarnetSalud"
                value={editedStudent?.puestoQueSolicitaCarnetSalud}
                onChange={handleInputChange}
                placeholder={student?.puestoQueSolicitaCarnetSalud}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.puestoQueSolicitaCarnetSalud
            )}
          </div>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Carnet de salud
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="carnetSalud"
                value={editedStudent?.carnetSalud}
                onChange={handleInputChange}
                placeholder={student?.carnetSalud}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.carnetSalud
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Con quien vive el estudiante
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="conQuienViveEstudiante"
                value={editedStudent?.conQuienViveEstudiante}
                onChange={handleInputChange}
                placeholder={student?.conQuienViveEstudiante}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.conQuienViveEstudiante
            )}
          </div>
        </div>
        <div className={`GM__${color}-students-details-info-row`}>
          <div className={`GM__${color}-students-details-info-cell-title`}>
            Distancia que hay de la escuela al hogar
          </div>
          <div className={`GM__${color}-students-details-info-cell`}>
            {isEditing ? (
              <input
                type="text"
                name="distanciaHogarEscuela"
                value={editedStudent?.distanciaHogarEscuela}
                onChange={handleInputChange}
                placeholder={student?.distanciaHogarEscuela}
                className={`GM__${color}-students-details-info-cell-input`}
              />
            ) : (
              student.distanciaHogarEscuela
            )}
          </div>
        </div>
      </div>
      <TableObservation student={student} />
    </div>
  );
}
