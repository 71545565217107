import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Button,
  Modal,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Grid,
} from "@mui/material";
import StudentsContext from "../../context/StudentsContext";

export default function FirmaModal({ open, onClose, observacion, studentId }) {
  const [tipoFirma, setTipoFirma] = useState("estudiante");
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [playbackTime, setPlaybackTime] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const { uploadFirma, fetchStudents } = useContext(StudentsContext);
  const mediaRecorderRef = useRef(null);
  const intervalRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    if (isRecording) {
      intervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isRecording]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleAudioTimeUpdate);
      audioRef.current.addEventListener("ended", handleAudioEnded);
      setAudioDuration(audioRef.current.duration);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener(
          "timeupdate",
          handleAudioTimeUpdate
        );
        audioRef.current.removeEventListener("ended", handleAudioEnded);
      }
    };
  }, [audioBlob]);

  const handleTipoFirmaChange = (event) => {
    setTipoFirma(event.target.value);
  };

  const handleStartRecording = () => {
    setRecordingTime(0);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();
        setIsRecording(true);

        const audioChunks = [];
        mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
          audioChunks.push(event.data);
        });

        mediaRecorderRef.current.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
          setIsRecording(false);
        });

        setTimeout(() => {
          if (mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
          }
        }, 60000); // Límite de 1 minuto (60000 ms)
      })
      .catch(function (err) {
        console.error("Error al obtener el flujo de audio: ", err);
      });
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const handlePlayRecording = () => {
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current = new Audio(audioUrl);
      audioRef.current.play();
    }
  };

  const handleAudioTimeUpdate = () => {
    setPlaybackTime(audioRef.current.currentTime);
  };

  const handleAudioEnded = () => {
    setPlaybackTime(0);
  };

  const handleSubmit = async () => {
    if (audioBlob) {
      try {
        const audioFile = new File([audioBlob], "audio.wav", {
          type: "audio/wav",
        });
        await uploadFirma(observacion.id, tipoFirma, audioFile, studentId);
        onClose();
        fetchStudents()
      } catch (error) {
        console.error("Error al subir el archivo de audio:", error);
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          outline: "none",
          width: "400px",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Firma de {tipoFirma}
        </Typography>
        <RadioGroup value={tipoFirma} onChange={handleTipoFirmaChange}>
          <FormControlLabel
            value="estudiante"
            control={<Radio />}
            label="Estudiante"
          />
          <FormControlLabel value="padre" control={<Radio />} label="Padre" />
        </RadioGroup>
        {isRecording && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">
              Tiempo de grabación: {formatTime(recordingTime)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(recordingTime / 60) * 100}
            />
          </Box>
        )}
        {audioBlob && !isRecording && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">
              Tiempo de reproducción: {formatTime(playbackTime)} /{" "}
              {formatTime(audioDuration)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(playbackTime / audioDuration) * 100}
            />
          </Box>
        )}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePlayRecording}
              disabled={!audioBlob || isRecording}
              fullWidth
            >
              Reproducir
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartRecording}
              disabled={isRecording}
              fullWidth
            >
              {isRecording ? "Grabando..." : "Iniciar grabación"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStopRecording}
              disabled={!isRecording}
              fullWidth
            >
              Detener grabación
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!audioBlob}
              fullWidth
            >
              Enviar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              fullWidth
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
