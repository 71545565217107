import React, { useContext } from 'react';
import AuthContext from '../context/AuthContex';
import AdminRoutes from './admin.routes';
import UserRoutes from './user.routes';
import PublicRoutes from './public.routes';
import { StudentsProvider } from '../context/StudentsContext';

const HashRoute = () => {
  const { user } = useContext(AuthContext);
  return (
    <>
      {user ? (
        user.rol === 'admin' ? (
          <StudentsProvider>
            <AdminRoutes />
          </StudentsProvider>
        ) : (
          <UserRoutes />
        )
      ) : (
        <PublicRoutes />
      )}
    </>
  );
};

export default HashRoute;