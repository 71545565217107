import React from 'react';

const CallButton = ({ userId, receiverId, onCallStart }) => {
    const handleCallClick = () => {
        onCallStart(receiverId);
      };

  return (
    <button className="call-button" onClick={handleCallClick}>
      Llamar
    </button>
  );
};

export default CallButton;