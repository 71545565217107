import React, { useContext } from "react";
import LayoutContext from "../../context/LayoutContex";
import AuthContext from "../../context/AuthContex"; // Importa el contexto de autenticación
import { Link } from "react-router-dom";
import {
  Home,
  AccountCircle,
  Dashboard,
  HelpOutline,
  ExpandLess,
  ExpandMore,
  Chat,
  HomeMax,
} from "@mui/icons-material"; // Importa los iconos necesarios de Material UI
import { IconButton } from "@mui/material";

const LeftNav = () => {
  const { isLeftNavOpen, color, toggleLeftNav } = useContext(LayoutContext);
  const { user } = useContext(AuthContext); // Obtén el usuario del contexto de autenticación

  return (
    <nav className={`GM__${color}-leftnav${isLeftNavOpen ? "-all" : ""}`}>
      <header className={`GM__${color}-header`}>
        <IconButton onClick={toggleLeftNav}>
          {isLeftNavOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </header>
      <ul>
        {user ? (
          user.rol === "admin" ? (
            // Rutas específicas para el rol de administrador
            <>
              <li>
                <Link to="/">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <HomeMax />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Inicio
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/dashboard">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <Dashboard />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Dashboard
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <AccountCircle />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Perfil
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/students">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <Home />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Estudiantes
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/help">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <HelpOutline />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Ayuda
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/chat">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <Chat />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Chat de voz
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/chat-group">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <Chat />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Chat Grupal
                    </span>
                  )}
                </Link>
              </li>
            </>
          ) : (
            // Rutas para usuarios autenticados que no son administradores
            <>
              <li>
                <Link to="/">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <HomeMax />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Inicio
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <AccountCircle />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Perfil
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/help">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <HelpOutline />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Solicitar Ayuda
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/chat">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <Chat />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Chat de voz
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/chat-group">
                  <span className={`GM__${color}-leftnav-route-icon`}>
                    <Chat />
                  </span>
                  {isLeftNavOpen && (
                    <span className={`GM__${color}-leftnav-route-title`}>
                      Chat Grupal
                    </span>
                  )}
                </Link>
              </li>
            </>
            
          )
        ) : (
          // Rutas para usuarios no autenticados
          <>
            <li>
              <Link to="/">
                <span className={`GM__${color}-leftnav-route-icon`}>
                  <Home />
                </span>
                {isLeftNavOpen && (
                  <span className={`GM__${color}-leftnav-route-title`}>
                    Home
                  </span>
                )}
              </Link>
            </li>
            <li>
              <Link to="/help">
                <span className={`GM__${color}-leftnav-route-icon`}>
                  <HelpOutline />
                </span>
                {isLeftNavOpen && (
                  <span className={`GM__${color}-leftnav-route-title`}>
                    Solicitar Ayuda
                  </span>
                )}
              </Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default LeftNav;
