import React from 'react';
import { Button as MuiButton } from '@mui/material';

const ButtonContinued = ({ children, onClick }) => {
  return (
    <MuiButton variant="contained" onClick={onClick}>
      {children}
    </MuiButton>
  );
};

export default ButtonContinued;