import React, { useContext, useState } from "react";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import StudentsContext from "../../context/StudentsContext";

export default function DeleteObservationModal({ open, onClose, observacion }) {
    const { deleteObservacion,  fetchStudents} = useContext(StudentsContext);

    const handleSubmit = () => {
      deleteObservacion(observacion.id);
      fetchStudents();
      onClose();
    };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          outline: "none",
          width: "400px",
        }}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            fullWidth
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
          >
            Eliminar
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
