import React from 'react';
import { TextField } from '@mui/material';

const InputForm = ({ label, type, value, onChange, variant, disabled, size, rows, className}) => {
  return (
    <TextField
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      variant={variant || "standard"}
      sx={{marginBottom: 0.3}}
      size={size ? size : "large"}
      fullWidth = {size ? false : true}
      disabled={disabled || false}
      rows={rows || 0}
      className={className || ""}
      
    />
  );
};

export default InputForm;