import React from 'react';

const ConsoleArt = () => {
  console.log(`%c
  GGGG   H   H   OOOOO    SSSSS  TTTTTTTT
 G       H   H  O     O  S          TT
 G       H   H  O     O   SSSSS     TT
 G  GG   HHHHH  O     O        S    TT
 G    G  H   H  O     O        S    TT
  GGGG   H   H   OOOOO   SSSSSS     TT
`, 'color: red;');

  return null; 
};

export default ConsoleArt;