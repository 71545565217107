import React, { useContext, useState } from "react";
import { Button, Modal, TextareaAutosize } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism"; // Estilo de código oscuro similar a VS Code
import LayoutContext from "../../context/LayoutContex";
import CodeIcon from "@mui/icons-material/Code";
import StudentsContext from "../../context/StudentsContext";
import SnackBartAlert from "../SnackBartAlert";

export default function MassStudentModal({
  isMassModalOpen,
  handleCloseMassModal,
}) {
  const { color } = useContext(LayoutContext);
  const { createStudentMassive } = useContext(StudentsContext);
  const [studentData, setStudentData] = useState("");
  const [showTextarea, setShowTextarea] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleTextareaChange = (event) => {
    setStudentData(event.target.value);
  };

const handleEnviarClick = async () => {
  setIsLoading(true);
  try {
    let studentsData;
    try {
      studentsData = JSON.parse(studentData);
    } catch (error) {
      throw new Error("Error al analizar el JSON, verifica la estructura.");
    }
    const success = await createStudentMassive(studentsData);
    setIsLoading(false);
    if (success) {
      setIsSuccess(true);
      setTimeout(() => {
        handleCloseMassModal();
        setIsSuccess(false);
      }, 2000);
    } else {
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 2000);
    }
  } catch (error) {
    setIsLoading(false);
    setIsError(true);
    setErrorMessage(error.message);
    setTimeout(() => {
      setIsError(false);
    }, 2000);
  }
};
  const isEnviarDisabled = studentData.trim() === "";

  const toggleView = () => {
    setShowTextarea(!showTextarea);
  };
  return (
    <Modal open={isMassModalOpen} onClose={handleCloseMassModal}>
      <div
        style={{
          width: "60%",
          height: "90%",
          margin: "auto",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <h2>Agregar en masa</h2>
        {/* Icono para alternar entre Textarea y SyntaxHighlighter */}
        {studentData && (
          <CodeIcon style={{ cursor: "pointer" }} onClick={toggleView} />
        )}

        <div style={{ height: "70%", overflowY: "auto", marginTop: "10px" }}>
          {showTextarea ? (
            <TextareaAutosize
              style={{
                width: "100%",
                height: "70%",
                resize: "none",
                marginTop: "10px",
              }}
              aria-label="textarea"
              placeholder='[
                                {
                                    "id": "1,123,319,045",
                                    "nombre": "Yobani Alexander Benavides Culma",
                                    "fechaNacimiento": "8-Oct-17",
                                    "padre": "Francisco Victor Benavides Mora",
                                    "madre": "Luz Clarita Culma Gonzales",
                                    "observaciones": [
                                        {
                                            "fecha": "30-May-23",
                                            "observacion": "No trabaja por estar en los puestos de los compañeros, es bastante desobediente."
                                        },
                                        {
                                            "fecha": "21-Mar-23",
                                            "observacion": "El niño demuestra dificultad para pronunciar las palabras correctamente."
                                        },
                                        {
                                            "fecha": "5-Oct-23","observacion": "Demuestra interés en el desarrollo de sus actividades en el aula."
                                        }
                                    ]
                                }]'
              onChange={handleTextareaChange}
              value={studentData}
            />
          ) : (
            <SyntaxHighlighter
              language="json"
              style={vscDarkPlus}
              customStyle={{ width: "100%", height: "100%", marginTop: "10px" }}
            >
              {studentData}
            </SyntaxHighlighter>
          )}
        </div>
        {isLoading && (
          <div>
            <p>Cargando...</p>
          </div>
        )}
        {isSuccess && (
          <div>
            <p>Carga masiva exitosa.</p>
          </div>
        )}
        {isError && (
          <div>
            <p>Error en la carga masiva.</p>
          </div>
        )}
        <SnackBartAlert severity={isError?"error": "success"} message={isError?errorMessage: "¡Se ha modificado con exito!"} openSnack={isSuccess|| isError} />

        <div
          className={`GM__${color}-students-footer`}
          style={{ textAlign: "right", marginTop: "10px" }}
        >
          <Button
            onClick={() => console.log("Aprender cómo subir los arreglos")}
            variant="outlined"
            color="primary"
          >
            Aprender cómo subir los arreglos
          </Button>
          <Button
            onClick={handleEnviarClick}
            disabled={isEnviarDisabled}
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
          >
            Enviar
          </Button>
          <Button
            onClick={handleCloseMassModal}
            variant="outlined"
            color="primary"
            style={{ marginLeft: "10px" }}
          >
            Cerrar
          </Button>
        </div>
      </div>
    </Modal>
  );
}
