import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../views/Home'
import Chat from '../components/chat/Chat';
import ChatGrupal from '../components/chat/ChatGrupal';

export default function UserRoutes() {
  console.log('userRoutes');
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/chat-group" element={<ChatGrupal />} />
    </Routes>
  )
}
