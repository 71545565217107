import React, { useState, useContext } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import StudentsContext from '../../context/StudentsContext';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const textareaStyle = {
  width: '100%',
  marginBottom: '16px',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '16px',
};

export default function UpdateObservationModal({ open, onClose, observacion }) {
  const [updatedObservacion, setUpdatedObservacion] = useState(observacion);
  const { updateObservacion, fetchStudents } = useContext(StudentsContext);

  const handleChange = (e) => {
    setUpdatedObservacion({
      ...updatedObservacion,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    updateObservacion(updatedObservacion, observacion.id);
    fetchStudents();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <h2>Actualizar Observación</h2>
        <TextField
          name="fecha"
          label="Fecha"
          value={updatedObservacion?.fecha || ''}
          onChange={handleChange}
          placeholder={observacion?.fecha  || 'Ingrese la fecha'}
          fullWidth
          margin="normal"
        />
        <TextField
          name="observacion"
          label="Observación"
          value={updatedObservacion?.observacion || ''}
          onChange={handleChange}
          placeholder={observacion?.observacion  || 'Ingrese la observacion'}
          multiline
          rows={4}
          sx={textareaStyle}
        />
        <TextField
          name="seguimiento"
          label="Seguimiento"
          value={updatedObservacion?.seguimiento || ''}
          onChange={handleChange}
          placeholder={observacion?.seguimiento  || 'Ingrese el seguimiento'}
          multiline
          rows={4}
          sx={textareaStyle}
        />
        <Box sx={buttonContainerStyle}>
          <Button onClick={onClose} color="primary" variant="outlined" sx={{ mr: 2 }}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Enviar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}